import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">
				
<path d="M687 1874 c-14 -14 -7 -35 11 -32 9 2 17 10 17 17 0 16 -18 25 -28
15z"/>
<path d="M890 1849 c-32 -13 -68 -47 -86 -81 -18 -35 -18 -111 1 -147 18 -36
78 -87 83 -72 2 6 -8 21 -22 32 -124 97 -21 299 127 250 107 -36 135 -167 52
-240 -42 -37 -47 -61 -8 -35 40 25 60 53 73 101 35 122 -100 240 -220 192z"/>
<path d="M880 1773 c-45 -41 -51 -98 -15 -141 15 -18 31 -29 37 -26 5 3 0 17
-13 30 -52 55 -11 144 66 144 25 0 40 -8 59 -31 14 -17 26 -39 26 -49 0 -24
-21 -70 -32 -70 -4 0 -8 -7 -8 -15 0 -25 19 -17 40 18 48 78 2 166 -87 167
-33 0 -50 -7 -73 -27z"/>
<path d="M917 1732 c-10 -10 -17 -32 -17 -48 l1 -29 17 35 c11 23 24 35 37 35
14 0 24 -11 32 -35 l12 -35 0 37 c1 54 -48 78 -82 40z"/>
<path d="M926 1608 c-23 -125 -79 -289 -123 -365 l-37 -62 -93 -3 c-56 -2 -92
-7 -91 -13 5 -16 740 -13 746 3 3 9 -20 12 -91 12 -78 0 -97 3 -110 18 -50 55
-138 307 -162 465 -3 20 -9 37 -14 37 -4 0 -15 -42 -25 -92z m68 -187 c-2 -2
-10 2 -19 9 -11 9 -15 30 -14 74 l0 61 19 -70 c10 -38 16 -72 14 -74z m-65 13
c-22 -27 -25 -14 -9 51 l15 65 3 -51 c2 -29 -2 -57 -9 -65z m11 -46 c0 -15
-13 -31 -37 -47 -29 -19 -35 -20 -29 -6 3 9 6 21 6 25 0 11 41 50 52 50 4 0 8
-10 8 -22z m51 9 c17 -13 53 -77 44 -77 -17 0 -75 55 -75 71 0 22 8 23 31 6z
m-72 -118 c-17 -13 -27 -15 -36 -7 -20 16 -16 24 20 47 l32 20 3 -21 c3 -14
-5 -28 -19 -39z m81 39 c33 -24 34 -26 21 -47 -13 -20 -61 15 -61 45 0 29 3
30 40 2z m-130 -67 c0 -4 -14 -15 -30 -24 l-30 -18 17 35 c13 28 19 33 30 25
7 -6 13 -14 13 -18z m220 -37 c0 -5 -20 5 -49 25 -12 8 -13 12 -1 26 11 14 15
12 31 -15 11 -17 19 -33 19 -36z m-152 4 l-3 -23 -13 23 c-11 19 -11 26 0 39
12 15 13 15 16 0 2 -9 2 -27 0 -39z m51 11 c-17 -33 -29 -31 -29 5 0 33 2 35
19 24 15 -9 17 -16 10 -29z m-82 -21 c13 -12 23 -24 23 -27 0 -3 -29 -6 -64
-6 -35 0 -63 2 -61 4 8 7 70 50 74 51 3 0 15 -10 28 -22z m153 -3 c22 -14 40
-26 40 -28 0 -1 -27 -2 -60 -2 -33 0 -60 1 -60 3 0 5 32 52 36 52 3 0 22 -11
44 -25z"/>
<path d="M1091 1514 c-52 -65 9 -152 78 -113 33 19 42 90 15 120 -25 28 -68
24 -93 -7z m75 -28 c9 -38 -4 -68 -29 -64 -23 3 -35 43 -23 74 9 24 45 17 52
-10z"/>
<path d="M1246 1524 c-50 -50 -11 -161 44 -126 23 14 26 5 6 -17 -9 -9 -12
-22 -7 -29 13 -22 39 19 42 68 3 35 0 45 -13 48 -11 2 -18 -5 -20 -20 -2 -13
-10 -23 -18 -23 -11 0 -15 11 -15 40 0 37 2 40 27 40 34 1 47 13 28 25 -23 15
-57 12 -74 -6z"/>
<path d="M1360 1465 l0 -75 45 0 c33 0 45 4 45 15 0 10 -10 15 -30 15 -23 0
-30 4 -30 20 0 15 7 20 25 20 14 0 25 5 25 10 0 6 -11 10 -25 10 -16 0 -25 6
-25 15 0 10 10 15 30 15 20 0 30 5 30 15 0 11 -12 15 -45 15 l-45 0 0 -75z"/>
<path d="M1470 1465 l0 -75 35 0 c43 0 85 37 85 75 0 38 -42 75 -85 75 l-35 0
0 -75z m75 0 c0 -32 -4 -41 -20 -43 -18 -2 -20 3 -20 43 0 40 2 45 20 43 16
-2 20 -11 20 -43z"/>
<path d="M1616 1473 c-14 -38 -26 -72 -26 -76 0 -16 29 -6 35 13 4 12 15 20
30 20 14 0 25 -7 28 -20 5 -18 37 -29 37 -12 -1 26 -50 138 -63 140 -10 2 -23
-18 -41 -65z"/>
<path d="M1710 1525 c0 -8 8 -15 18 -15 15 0 17 -8 14 -60 -4 -55 -2 -60 17
-60 19 0 21 6 21 59 0 50 3 59 20 64 41 11 20 27 -35 27 -42 0 -55 -3 -55 -15z"/>
<path d="M1851 1518 c-5 -13 -18 -47 -30 -75 -20 -51 -20 -53 -2 -53 10 0 21
9 24 20 3 14 14 20 31 20 19 0 26 -5 26 -20 0 -14 7 -20 21 -20 13 0 19 5 16
13 -2 6 -14 40 -27 75 -24 65 -44 79 -59 40z m38 -60 c1 -5 -6 -8 -14 -8 -15
0 -21 31 -8 44 7 6 20 -17 22 -36z"/>
<path d="M1962 1528 c-20 -20 -14 -45 18 -68 17 -12 30 -26 30 -32 0 -6 -12
-8 -30 -4 -22 4 -30 2 -30 -8 0 -31 63 -35 83 -5 20 28 12 51 -26 74 l-32 19
33 4 c20 2 32 8 29 15 -5 17 -59 21 -75 5z"/>
<path d="M2060 1485 c0 -42 5 -60 20 -75 25 -25 62 -26 83 -2 23 25 24 132 2
132 -11 0 -15 -12 -15 -44 0 -57 -8 -76 -31 -76 -17 0 -19 8 -19 60 0 53 -2
60 -20 60 -18 0 -20 -7 -20 -55z"/>
<path d="M2204 1465 l-1 -75 31 0 c57 0 98 47 60 68 -11 6 -13 11 -5 16 18 11
13 44 -8 56 -11 5 -32 10 -48 10 l-28 0 -1 -75z m64 33 c2 -9 -4 -18 -13 -21
-11 -5 -15 0 -15 19 0 28 23 29 28 2z m0 -60 c2 -9 -4 -18 -13 -21 -11 -5 -15
0 -15 19 0 28 23 29 28 2z"/>
<path d="M2401 1514 c-45 -58 -4 -136 64 -122 27 5 21 23 -11 28 -26 5 -29 9
-29 45 0 32 4 40 20 43 11 2 27 5 35 8 13 4 13 6 0 14 -27 17 -58 10 -79 -16z"/>
<path d="M2517 1522 c-29 -31 -31 -74 -7 -105 24 -30 55 -35 88 -11 29 20 31
93 4 117 -24 22 -65 21 -85 -1z m69 -36 c9 -38 -4 -68 -29 -64 -23 3 -35 43
-23 74 9 24 45 17 52 -10z"/>
<path d="M2650 1481 c0 -33 -3 -66 -6 -75 -4 -12 0 -16 16 -16 20 0 22 4 18
40 -2 22 -1 40 3 40 3 0 11 -18 18 -40 15 -49 36 -51 51 -5 16 49 26 51 22 4
-4 -35 -2 -39 18 -39 18 0 21 4 16 23 -3 12 -6 46 -6 75 0 40 -4 52 -15 52 -9
0 -19 -10 -24 -22 -5 -13 -15 -39 -23 -58 l-15 -35 -18 58 c-14 42 -23 57 -37
57 -15 0 -18 -9 -18 -59z"/>
<path d="M2330 1410 c0 -11 7 -20 15 -20 8 0 15 9 15 20 0 11 -7 20 -15 20 -8
0 -15 -9 -15 -20z"/>
<path d="M750 1120 c0 -5 16 -10 35 -10 19 0 35 5 35 10 0 6 -16 10 -35 10
-19 0 -35 -4 -35 -10z"/>
<path d="M830 1120 c0 -5 24 -11 53 -11 28 -1 41 -4 27 -6 -60 -11 -40 -23 38
-23 81 0 113 15 50 23 -23 3 -16 4 20 5 28 1 52 7 52 12 0 6 -47 10 -120 10
-73 0 -120 -4 -120 -10z"/>
<path d="M1085 1120 c3 -5 19 -10 36 -10 16 0 29 5 29 10 0 6 -16 10 -36 10
-21 0 -33 -4 -29 -10z"/>
</g>
</svg>

		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
